<template>
  <div class="order" v-if="order">
    <ShopNavBar title="订单" />

    <!-- <div class="order-header">
      <div>{{ order.orderstateName }}</div>
      <div v-if="order.state == 0">订单未付款，请完成支付</div>
      <div v-if="order.state == 1">订单已付款，等待卖家发货</div>
    </div> -->

    <div>
      <div class="address-box">
        <q-icon name="room" color="grey" size="18px"></q-icon>
        <div class="address-info">
          <div>{{ order.addressUsername }} {{ order.addressUsertel }}</div>
          <div class="address-details">
            {{ order.addressSheng + order.addressShi + order.addressXian + order.addressAddress }}
          </div>
        </div>
      </div>
    </div>

    <div class="goods-list">
      <div class="goods-item" v-for="(item, index) in order.dbShopOrderChild" :key="index">
        <img class="goods-img" :src="getimg(item.img[0])" />
        <div class="order-goods-content">
          <div class="goods-name">{{ item.goodsName }}</div>
          <div class="goods-label">规格：{{ item.goodsModelName }}</div>
          <div class="goods-row">
            <div class="goods-price">￥{{ item.price }}</div>
            <div class="goods-num">x {{ item.num }}</div>
          </div>
        </div>
      </div>
      <div class="total">
        共<span>{{ order.num }} </span>件商品，合计<span>￥{{ order.amount }}</span>
      </div>
    </div>

    <div class="cell-box">
      <van-cell title="订单编号" :value="order.number" />
      <van-cell title="下单时间" :value="order.cdate" />
      <van-cell title="发货时间" :value="order.fdate" />
      <van-cell title="快递名" :value="order.kuaidiname">
        <template #default>
          <view class="cell-input">
            <van-field v-model="order.kuaidiname" label="" placeholder="请输入快递名" label-align="right" :readonly="order.state != 1" />
          </view>
        </template>
      </van-cell>
      <van-cell title="快递单号" :value="order.kuaidiNo">
        <template #default>
          <view class="cell-input">
            <van-field v-model="order.kuaidiNo" label="" placeholder="请输入快递单号" label-align="right" :readonly="order.state != 1" />
          </view>
        </template>
      </van-cell>
      <!-- <van-cell title="备注" :value="order.beizhu" /> -->
      <van-cell title="状态" :value="order.orderstateName" />
    </div>

    <div class="footer" v-if="order.state == 1">
      <div @click="fahuo">
        发货
      </div>
      <div @click="wuliudanhao">
        修改物流单号
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Cell, Field, Toast } from 'vant'

export default {
  components: {
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Toast.name]: Toast
  },
  setup() {
    return {
      order: ref([]),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.id = this.$route.query.id
    this.getdata()
  },
  methods: {
    getimg(img) {
      if (img != "") {
        return this.$config.send_url + "Upload/" + img;
      }
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "Api/ShopOrder/ShopUserGetOrderDetails",
        {
          userid: _this.us.userid,
          oid: _this.id
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            data.dbShopOrderChild.forEach(item => {
              item.img = item.img.split(",")
            })
            _this.order = data
            // console.log(data)
          }
        }
      )
    },
    wuliudanhao() {
      let _this = this
      if (_this.order.kuaidiname == '') {
        Toast('请输入快递名')
        return
      }
      if (_this.order.kuaidiNo == '') {
        Toast('快递单号')
        return
      }
      _this.$request.post(
        "api/ShopOrder/ShopUserWuliudanhao",
        {
          userid: _this.us.userid,
          oid: _this.id,
          kuaidiNo: _this.order.kuaidiNo,
          kuaidiname: _this.order.kuaidiname
        },
        (res) => {
          _this.$q.dialog({
            message: res.data.msg,
          })
          if (res.data.code == 100) {
            _this.getdata()
          }
        }
      )
    },
    fahuo() {
      let _this = this
      _this.$q.dialog({
        message: "确定要发货吗？",
        cancel: true,
      }).onOk(() => {
        _this.$request.post(
          "api/ShopOrder/ShopUserFahuo",
          {
            
            userid: _this.us.userid,
            oid: _this.id
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
            })
            if (res.data.code == 100) {
              _this.getdata()
            }
          }
        )
      })

    }
  }
}
</script>

<style scoped>
.order {
  padding-bottom: 50px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.order-header {
  padding: 0 20px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.address-box {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.address-info {
  margin-left: 10px;
}

.address-details {
  font-size: 12px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.goods-list {
  margin-top: 10px;
  background-color: #fff;
}

.goods-item {
  padding: 10px;
  display: flex;
}

.goods-img {
  width: 80px;
  height: 80px;
}

.order-goods-content {
  padding-left: 10px;
  flex: 1;
}

.goods-label {
  color: gray;
  font-size: 13px;
}

.goods-price {
  color: #ff2d2e;
}

.goods-num {
  font-size: 12px;
  color: gray;
}

.goods-row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.total {
  padding: 10px 14px;
  text-align: right;
  border-top: 1px solid rgba(228, 225, 225, .3);
}

.total span {
  color: #ff2d2e;
}

.cell-box {
  margin-top: 10px;
  background-color: #fff;
}

.address-wrap {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.address-shou {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: #29B7C3;
}

.address-info {
  flex: 1;
  margin-left: 10px;
}

.cell-input :deep(.van-cell) {
  padding: 0;
  text-align: right;
}

.cell-input :deep(.van-field__control) {
  text-align: right;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
}

.footer>div {
  display: flex;
  align-items: center;
}

.footer div:nth-child(1) {
  color: #ff2d2e;
}

.footer div:nth-child(2) {
  color: #fe9200;
}
</style>